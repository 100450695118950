$bg: #eee;
$text: #444;
$strong: #111;
$dim: #999;
$links: #55f;
$rule: #999;
$tr-rule: #ccc;
$highlight: #ccc;

$width: 800px;
$margin: 17px;
$in_width: $width - 2*$margin;
$small-width: 500px;

/* reset */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
 margin:0;
 padding:0;
 border:0;
 font:inherit;
 font-size:100%;
 vertical-align:baseline
}

@font-face {
 font-family:"OpenSans";
 src:url('/css/fonts/OpenSans.woff') format('woff')
}
@font-face {
 font-family:"OpenSansLight";
 src:url('/css/fonts/OpenSans-Light.woff') format('woff')
}
@font-face {
 font-family:"OpenSans";
 src:url('/css/fonts/OpenSans-Italic.woff') format('woff');
 font-style:italic
}

html, body { height: 100%; text-align: justify;
             background-color: $bg; color: $text;
             font-family: "OpenSans", sans;
             line-height: 1.4em; }

a, summary { text-decoration: none; color: $links; }
a:hover, summary:hover { color: #009; }

strong { font-weight: bold; }

sup { vertical-align: super; font-size: x-small; }
sub { vertical-align: sub; font-size: x-small; }

hr { height: 1px; border: none; background-color: $rule; margin-top: 1em; }

section h1 { font-size: 150%; border-left: 4px solid $links;
             padding-left: 11px; margin-left: -$margin; font-weight: bold; }

section h2 { border-left: 2px solid #afafff;
             padding-left: 11px; margin-left: -$margin; }

section h2 { font-size: 120%; font-weight: bold; }

section h3 { font-size: 100%; font-weight: bold; }

section.subsection {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
}

details { border-radius: 10px; padding: 5px 20px; margin-top: 0.5em; }

details { background-color: #f7f7f7; }

details[open] summary { margin-bottom: 1em; }

h1 {
padding: 0.2em 0 0.2em 0.5em;
margin: 1.0em 0 0.2em -0.5em; }

h2 {
padding: 0.2em 0 0.2em 0.5em;
margin: 0.8em 0 0.2em -0.5em; }

h3, h4, h5 {
padding: 0.2em 0 0.2em 0.3em;
margin: 0.2em 0 0.0em 0.3em; }

header { text-align: center; font-family: "OpenSansLight", sans;
         line-height: 1.3em; font-weight: 400; font-size: 1.2em;
         padding-top: 1em; }

header h1 { font-size: 1.5em; margin:0 0 0.3em 0; }

header a { color: $text; }

pre { background-color: white; padding: 0.5em; margin: 0.5em 0; }
code { font-family: mono;background-color: white;color: #333;padding-left: 0.3em; padding-right: 0.3em; }

code.language-ssh-key { word-wrap: break-word; word-break: break-all; white-space: pre-line; }

nav { text-align: center; font-size: 1.2em; margin: 1em 0; }
nav ul { display: flex; flex-flow: row wrap; list-style: none; justify-content: space-around; }
nav ul li { margin: 0; clear: both; padding: 0 10px 0 11px; }

footer { font-size: 75%; padding-bottom: 175px; }

em { font-style: italic; }

table { width: 100%; border-collapse: collapse; }
th, td { padding: 0.3em; }
tr { border-bottom: 1px dotted $tr-rule; }

table.headerline th { border-bottom: 1px solid #555; }

table.bordered { border-collapse: collapse; margin: 1em 0; }
table.bordered tr { border-top: 1px solid #bbb; border-bottom: 1px solid #bbb; }

:target { color: #5151ff; background-color: yellow; }

.non_full { width: auto; }

.publi_table td:nth-child(1) { width: 30%; }
.publi_table td:nth-child(2) { width: 60%; }
.publi_table td:nth-child(3) { width: 10%; }

ul { list-style-image: url('/images/bullet_dark.svg'); }
li ul { list-style-image: url('/images/bullet_dark_single.svg'); }
ul li { margin-left: 1em; }

ol { list-style-type: decimal; }
ol li { padding: 0.5em 10px 0.5em 0; }
ol li:nth-child(n+2) { border-top: 1px solid #ddd; }
ol li:hover { border-right: 3px solid $links; padding-right: 7px; }

ol.publication-list li { background: linear-gradient(0deg, rgba(2,0,36,0) 0%, #f4f4f4 100%); padding-left: 10px; }

.float-left { float: left; }
.right, .float-right { float: right; margin-left: 1em; }
.clear { clear: both; }

.switcher { text-align: center; font-size: 2em; margin: 0.5em 0; }
.switcher > a:hover { text-decoration: none; }

.switch_flag { height: 1em; }

.credits { color: $dim; }

.centered_img { display: block; margin: 1em auto; }

.center { text-align: center; }

#wrapper { max-width: $in_width; margin: auto; }
#outter-wrapper { width: 100%; height: auto; min-height: 100%; position: relative; }
#foot_image { height: 150px; background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 50%, #EEEEEE 100%);
          position: absolute; bottom: 0px; width: 100%; text-align: center; overflow: hidden; }
#clouds { background-image: url('../images/clouds.png'); height: 150px; width: 200%;
          position: relative; top: -150px; margin-bottom: -150px; }
#foot_image:active #ski { visibility: visible; animation: ski 3s linear; }
#clouds:hover { animation: clouds 100s infinite linear; }
#ski { position: relative; visibility: hidden; top: -107px; left: -210px; }

#portrait { margin: 0 15px 15px 0; padding: 23px 0 18px 0; width: 150px; display: inline-block; vertical-align:middle; }

#info { display: flex; flex-flow: row wrap; width: 100%; justify-content: space-evenly; align-items: center; }
#info a { flex-basis: 160px; }
#resume { flex-basis: 450px; flex-grow: 2; }

#page_subtitle { font-family: "OpenSansLight",sans; font-size: 2em; margin: 1em; text-align: center; line-height: 1em; }

#main_email { padding: 0.5em; }

#http_code { text-align: center; font-size: 10em; line-height: 1em; }

#list_submitted { list-style-type: circle }
#list_accepted { list-style-type: circle }
#list_published { list-style-type: decimal }

.footnotes { font-size: 0.8em; padding-left: 1em; margin-top: 2em;}
.footnotes ol li { padding: 0; border: none; }

.cc-icon { height: 1em; vertical-align: middle; }

@media (max-width: $small-width) {
    body { font-size: 80%; }
}

@media (max-width: $width) {
  #outter-wrapper { width: 100%; }
  header h1 { font-size: 1.2em; }
  header { font-size: 1em; }
  nav { font-size: 1.5em; line-height: 1.5em; }
  body { padding: 0 $margin; }
  #foot_image { display: none; }
  footer { display: none; }
}

@keyframes clouds {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-774px, 0); }
}

@keyframes ski {
  0% { transform: translate(0, 0) rotate(0deg); }
  10% { transform: translate(10px, 0) rotate(0deg); }
  20% { transform: translate(10px, 8px) rotate(45deg); }
  25% { transform: translate(25px, 16px) rotate(15deg); }
  30% { transform: translate(55px, 32px) rotate(0deg); }
  40% { transform: translate(90px, 20px) rotate(-20deg); }
  50% { transform: translate(110px, 15px) rotate(-200deg); }
  60% { transform: translate(130px, 35px) rotate(-380deg); }
  68% { transform: translate(164px, 27px) rotate(-360deg); }
  100% { transform: translate(300px, 66px) rotate(20deg); }
}
